import * as Types from './types';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { useFetchData } from '@/graphql/fetcher';

export const GetOrganizationByKeyDocument = `
    query getOrganizationByKey($organizationKey: String!) {
  organizationByKey(key: $organizationKey) {
    id
  }
}
    `;
export const useGetOrganizationByKeyQuery = <
      TData = Types.GetOrganizationByKeyQuery,
      TError = unknown
    >(
      variables: Types.GetOrganizationByKeyQueryVariables,
      options?: UseQueryOptions<Types.GetOrganizationByKeyQuery, TError, TData>
    ) =>
    useQuery<Types.GetOrganizationByKeyQuery, TError, TData>(
      ['getOrganizationByKey', variables],
      useFetchData<Types.GetOrganizationByKeyQuery, Types.GetOrganizationByKeyQueryVariables>(GetOrganizationByKeyDocument).bind(null, variables),
      options
    );
export const CreateReupPurchaseOrderDocument = `
    mutation createReupPurchaseOrder($input: CreateReupPurchaseOrderInput!) {
  purchaseOrder: createReupPurchaseOrder(input: $input) {
    buyer {
      id
    }
    reupPurchaseOrder {
      id
      poNumber
      displayPoNumber
      buyer {
        id
        accountId
        licenseName
        licenseNumber
      }
      supplierId
      supplierName
      note
      totalPrice
      createdAt
      updatedAt
    }
  }
}
    `;
export const useCreateReupPurchaseOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateReupPurchaseOrderMutation, TError, Types.CreateReupPurchaseOrderMutationVariables, TContext>) =>
    useMutation<Types.CreateReupPurchaseOrderMutation, TError, Types.CreateReupPurchaseOrderMutationVariables, TContext>(
      ['createReupPurchaseOrder'],
      useFetchData<Types.CreateReupPurchaseOrderMutation, Types.CreateReupPurchaseOrderMutationVariables>(CreateReupPurchaseOrderDocument),
      options
    );
export const GetReupPurchaseOrdersDocument = `
    query getReupPurchaseOrders($poFilter: ReupPurchaseOrderFilter) {
  purchaseOrders: reupPurchaseOrders(filter: $poFilter) {
    nodes {
      id
      supplierId
      supplierName
      poNumber
      status
      displayPoNumber
      note
      buyerId
      buyer {
        id
        licenseName
        licenseNumber
      }
      totalPrice
      createdAt
      updatedAt
      organizationKey
      reupLineItems {
        nodes {
          id
          unitPrice
          isDeleted
          totalPrice
          purchaseOrderId
          requiredQuantity
          requestedQuantity
          suggestedQuantity
          productId
          productName
        }
      }
    }
  }
}
    `;
export const useGetReupPurchaseOrdersQuery = <
      TData = Types.GetReupPurchaseOrdersQuery,
      TError = unknown
    >(
      variables?: Types.GetReupPurchaseOrdersQueryVariables,
      options?: UseQueryOptions<Types.GetReupPurchaseOrdersQuery, TError, TData>
    ) =>
    useQuery<Types.GetReupPurchaseOrdersQuery, TError, TData>(
      variables === undefined ? ['getReupPurchaseOrders'] : ['getReupPurchaseOrders', variables],
      useFetchData<Types.GetReupPurchaseOrdersQuery, Types.GetReupPurchaseOrdersQueryVariables>(GetReupPurchaseOrdersDocument).bind(null, variables),
      options
    );
export const SaveReupDraftOrdersDocument = `
    mutation saveReupDraftOrders($input: [ReupOrderInput]!) {
  purchaseOrders: saveReupDraftOrders(input: $input) {
    id
    buyer {
      licenseName
      licenseNumber
      id
    }
    poNumber
    displayPoNumber
    createdAt
    updatedAt
    supplierId
    supplierName
    isDeleted
    status
    reupLineItems {
      nodes {
        id
        unitPrice
        totalPrice
        purchaseOrderId
        requiredQuantity
        requestedQuantity
        suggestedQuantity
        productId
        productName
      }
    }
  }
}
    `;
export const useSaveReupDraftOrdersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SaveReupDraftOrdersMutation, TError, Types.SaveReupDraftOrdersMutationVariables, TContext>) =>
    useMutation<Types.SaveReupDraftOrdersMutation, TError, Types.SaveReupDraftOrdersMutationVariables, TContext>(
      ['saveReupDraftOrders'],
      useFetchData<Types.SaveReupDraftOrdersMutation, Types.SaveReupDraftOrdersMutationVariables>(SaveReupDraftOrdersDocument),
      options
    );
export const UpdatePurchaseOrderDocument = `
    mutation updatePurchaseOrder($input: UpdateReupPurchaseOrderInput!) {
  purchaseOrderQuery: updateReupPurchaseOrder(input: $input) {
    reupPurchaseOrder {
      id
      reupLineItems {
        nodes {
          id
        }
      }
    }
  }
}
    `;
export const useUpdatePurchaseOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdatePurchaseOrderMutation, TError, Types.UpdatePurchaseOrderMutationVariables, TContext>) =>
    useMutation<Types.UpdatePurchaseOrderMutation, TError, Types.UpdatePurchaseOrderMutationVariables, TContext>(
      ['updatePurchaseOrder'],
      useFetchData<Types.UpdatePurchaseOrderMutation, Types.UpdatePurchaseOrderMutationVariables>(UpdatePurchaseOrderDocument),
      options
    );
export const CreateLineItemDocument = `
    mutation createLineItem($input: CreateReupLineItemInput!) {
  lineItem: createReupLineItem(input: $input) {
    reupLineItem {
      id
      productId
      purchaseOrderId
      requestedQuantity
    }
  }
}
    `;
export const useCreateLineItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateLineItemMutation, TError, Types.CreateLineItemMutationVariables, TContext>) =>
    useMutation<Types.CreateLineItemMutation, TError, Types.CreateLineItemMutationVariables, TContext>(
      ['createLineItem'],
      useFetchData<Types.CreateLineItemMutation, Types.CreateLineItemMutationVariables>(CreateLineItemDocument),
      options
    );
export const UpdateLineItemDocument = `
    mutation updateLineItem($input: UpdateReupLineItemInput!) {
  lineItem: updateReupLineItem(input: $input) {
    reupLineItemEdge {
      node {
        id
        requestedQuantity
        purchaseOrder {
          id
        }
      }
    }
  }
}
    `;
export const useUpdateLineItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateLineItemMutation, TError, Types.UpdateLineItemMutationVariables, TContext>) =>
    useMutation<Types.UpdateLineItemMutation, TError, Types.UpdateLineItemMutationVariables, TContext>(
      ['updateLineItem'],
      useFetchData<Types.UpdateLineItemMutation, Types.UpdateLineItemMutationVariables>(UpdateLineItemDocument),
      options
    );
export const GetLicensesDocument = `
    query getLicenses($filter: LicenseFilter) {
  licenses: licenses(filter: $filter) {
    nodes {
      id
      licenseName
      licenseNumber
      createdAt
      city
      postalCode
      accountId
      billingAccountId
      productType
      addressLine1
      addressLine2
      activationStatus
    }
  }
}
    `;
export const useGetLicensesQuery = <
      TData = Types.GetLicensesQuery,
      TError = unknown
    >(
      variables?: Types.GetLicensesQueryVariables,
      options?: UseQueryOptions<Types.GetLicensesQuery, TError, TData>
    ) =>
    useQuery<Types.GetLicensesQuery, TError, TData>(
      variables === undefined ? ['getLicenses'] : ['getLicenses', variables],
      useFetchData<Types.GetLicensesQuery, Types.GetLicensesQueryVariables>(GetLicensesDocument).bind(null, variables),
      options
    );
export const GetLowStockProductsDocument = `
    query getLowStockProducts($orgkey: String!, $storekey: String!, $limit: Int) {
  products: getReupProducts(orgkey: $orgkey, storekey: $storekey, limit: $limit) {
    key
    ProductName
    QuantityOnHand
    DefaultEstimatedStockOut
    EstimatedDailyLoss
  }
}
    `;
export const useGetLowStockProductsQuery = <
      TData = Types.GetLowStockProductsQuery,
      TError = unknown
    >(
      variables: Types.GetLowStockProductsQueryVariables,
      options?: UseQueryOptions<Types.GetLowStockProductsQuery, TError, TData>
    ) =>
    useQuery<Types.GetLowStockProductsQuery, TError, TData>(
      ['getLowStockProducts', variables],
      useFetchData<Types.GetLowStockProductsQuery, Types.GetLowStockProductsQueryVariables>(GetLowStockProductsDocument).bind(null, variables),
      options
    );
export const GetReupProductsDocument = `
    query getReupProducts($orgkey: String!, $storekey: String!, $limit: Int) {
  products: getReupProducts(orgkey: $orgkey, storekey: $storekey, limit: $limit) {
    key
    OrganizationKey
    StoreId
    StoreName
    LicenseNum
    ProductName
    CategoryType
    StrainType
    StoreKey
    StoreName
    CustomCategory
    QuantityOnHand
    Velocity_12Weeks
    Velocity_2Weeks
    Velocity_1Week
    Velocity_7Days_Actual
    EstimatedStockOut_1Week
    EstimatedStockOut_2Weeks
    EstimatedStockOut_12Weeks
    DefaultEstimatedStockOut
    EstimatedDailyLoss
    UnitPrice
    MedianPrice
    Suppliers {
      SupplierId
      SupplierName
      SupplierLicenseNumber
      UnitCost
    }
  }
}
    `;
export const useGetReupProductsQuery = <
      TData = Types.GetReupProductsQuery,
      TError = unknown
    >(
      variables: Types.GetReupProductsQueryVariables,
      options?: UseQueryOptions<Types.GetReupProductsQuery, TError, TData>
    ) =>
    useQuery<Types.GetReupProductsQuery, TError, TData>(
      ['getReupProducts', variables],
      useFetchData<Types.GetReupProductsQuery, Types.GetReupProductsQueryVariables>(GetReupProductsDocument).bind(null, variables),
      options
    );
export const GetReupSuppliersDocument = `
    query getReupSuppliers($orgkey: String!) {
  suppliers: getReupSuppliers(orgkey: $orgkey) {
    SupplierId
    SupplierName
    SupplierLicenseNumber
  }
}
    `;
export const useGetReupSuppliersQuery = <
      TData = Types.GetReupSuppliersQuery,
      TError = unknown
    >(
      variables: Types.GetReupSuppliersQueryVariables,
      options?: UseQueryOptions<Types.GetReupSuppliersQuery, TError, TData>
    ) =>
    useQuery<Types.GetReupSuppliersQuery, TError, TData>(
      ['getReupSuppliers', variables],
      useFetchData<Types.GetReupSuppliersQuery, Types.GetReupSuppliersQueryVariables>(GetReupSuppliersDocument).bind(null, variables),
      options
    );